import logo from './logo.svg';
import './App.css';
import React, {StrictMode, useState} from 'react';
import { createRoot } from 'react-dom/client';
import * as leaflet from 'leaflet';
import {MapContainer, Marker, Popup, TileLayer, FeatureGroup, Polygon} from 'react-leaflet';
import { BasemapLayer, FeatureLayer } from "react-esri-leaflet";


// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React For Real!
//         </a>
//       </header>
//     </div>
//   )
const posi = { lat: 41.54751, lng: -70.588 }

function colorIcon(url) {
  return new L.icon({
    iconUrl: url,
    iconSize:     [15, 15], // size of the icon
});
}

/*const features = fetch('https://services1.arcgis.com/QWTIBrIwJUnIdXND/arcgis/rest/services/Test_Org__Falmouth_Municipal_Propagation_/FeatureServer/3/query?where=OID+IS+NOT+NULL&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&relationParam=&returnGeodetic=false&outFields=&returnGeometry=true&returnCentroid=false&returnEnvelope=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&defaultSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token=')
.then((response=>features.json()))
.then((features2=L.geoJson(response)));
//const response = L.geoJSON(features)
console.log(features2)*/

function test() {
  return fetch('https://services1.arcgis.com/QWTIBrIwJUnIdXND/arcgis/rest/services/Test_Org__Falmouth_Municipal_Propagation_/FeatureServer/3/query?where=OID+IS+NOT+NULL&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&relationParam=&returnGeodetic=false&outFields=&returnGeometry=true&returnCentroid=false&returnEnvelope=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&defaultSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token=')
  .then((response) => response.json())
  .then((responseJson) => {
    console.log(responseJson);
    return responseJson;
  })
  .catch((error) => {
    console.error(error);
  });
}
const mapFeatures = await test();
console.log(mapFeatures.features[0].geometry.coordinates[0])

var redIcon = colorIcon('https://msengen.github.io/codepen-images/red%20dot.png');
var blueIcon = colorIcon('https://msengen.github.io/codepen-images/blue%20dot.png');

function App() {
    return(
      <StrictMode>
      <div className="App">
        
      <MapContainer center={posi} zoom={16} style={{ height:"500px",backgroundColor:"red",marginTop:"10px", marginBottom:'10px'}}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />

          {/* <FeatureLayer url={'https://services1.arcgis.com/QWTIBrIwJUnIdXND/arcgis/rest/services/Test_Org__Falmouth_Municipal_Propagation_/FeatureServer/3'} 
            eventHandlers = {{click: () => console.log('featurelayer')}}/> */}

          {/* fetch('https://services1.arcgis.com/QWTIBrIwJUnIdXND/arcgis/rest/services/Test_Org__Falmouth_Municipal_Propagation_/FeatureServer/3') */}

          {/* var test_feature_collection=FeatureLayer url={'https://services1.arcgis.com/QWTIBrIwJUnIdXND/arcgis/rest/services/Test_Org__Falmouth_Municipal_Propagation_/FeatureServer/3'
          
          test_feature_collection.features.map((feature,index) => { 
              return (  
                <FeatureGroup
              )
          }) */}

        {mapFeatures.features.map((feature, index) => {
          return (
            <FeatureGroup>
              <Polygon positions= {feature.geometry.coordinates[0]}>
              </Polygon>
              
            </FeatureGroup>
          )
        })}

          <Marker
            position = {posi}
            icon = {redIcon}>
            <Popup>

              <button onClick={() => console.log('hi')}>"hello!"</button>

            </Popup>
          </Marker> 

      </MapContainer>
      </div>
      
       <div>
        <button onClick={() => console.log('hi!')}>hello</button>
      </div>
      </StrictMode>
    );
  // );
}

export default App;
